import LandingPage from "../containers/LandingPage";

export default LandingPage;

export const pageQuery = graphql`
  query LandingPageEsQuery {
    site {
      siteMetadata {
        languages {
          langs
        }
      }
    }
    content: allContentfulLandingPage(
      filter: { node_locale: { eq: "es" } }
    ) {
      ...LandingPageFragment
    }
  }
`;
